<template>
  <Stack
    tag="section"
    gap="none"
    class="!w-full sm:h-[296px] lg:h-[400px]"
    direction="col"
    :breakpoints="{ direction: { sm: 'row' } }"
  >
    <div
      class="w-full sm:w-1/2 lg:3/5 h-full pl-xs py-xs sm:pt-[74px] sm:pl-sm md:pl-md xl:pl-[189px]"
      :class="isHomeBranch ? 'bg-charcoal-default' : 'bg-mkm-yellow-default'"
    >
      <div>
        <Heading
          tag="h1"
          size="xl"
          weight="semi"
          :display-font="!isHomeBranch"
          class="uppercase"
          :class="isHomeBranch ? 'text-mkm-yellow-default' : 'text-white'"
          data-test-id="branchDetailsHeroTitle"
          data-fs="branchDetailsHeroTitle"
          >{{ heroTitle(page) }}</Heading
        >
        <Text
          tag="p"
          size="sm"
          weight="semi"
          class="max-w-[640px] mb-xs"
          :class="isHomeBranch ? 'text-white' : 'text-mkm-blue-default'"
          data-test-id="branchDescription"
          data-fs="branchDescription"
          v-html="heroDescription(page)"
        />

        <div
          v-if="branch && shouldShowBranchSelect && !isNewBranch(branch[0])"
          data-test-id="branchDetailsSelectBranch"
          data-fs="branchDetailsSelectBranch"
        >
          <Button
            v-if="!isUserSelectedBranch"
            :variant="isHomeBranch ? 'neutral' : 'secondary'"
            size="md"
            @click="handleSelectBranch"
            >Select Your Branch</Button
          >

          <Button
            v-else
            :variant="isHomeBranch ? 'neutral' : 'outline'"
            size="md"
            class="!pointer-events-none"
            :class="isHomeBranch && '!bg-grey-light'"
          >
            <template #before>
              <Icon name="check-thick" :size="16" />
            </template>

            Selected Branch
          </Button>
        </div>
      </div>
    </div>

    <div
      class="h-full overflow-hidden"
      :class="[
        'relative [ w-full sm:w-1/2 ] [ pb-2xs sm:pb-none sm:pr-2xs ]',
        'before:hidden before:sm:block before:absolute before:left-none before:bottom-none before:border-[2rem] before:border-b-transparent before:border-r-transparent before:border-y-[250px] before:border-x-[64px] before:md:border-x-[100px]',
        'after:hidden after:sm:block after:absolute after:right-none sm:after:bottom-none after:border-[2rem] after:border-t-transparent after:border-l-transparent after:border-y-[250px] after:lg:border-y-[400px] after:border-x-[64px] after:md:border-x-[100px] after:lg:border-x-[150px] ',
        isHomeBranch
          ? 'before:border-charcoal-default after:border-mkm-yellow-default bg-mkm-yellow-default'
          : 'before:border-mkm-yellow-default after:border-mkm-blue-default bg-mkm-blue-default ',
      ]"
    >
      <section
        class="p-2xs sm:p-sm md:p-lg skew-x-[0deg] sm:skew-x-[-14.25deg] md:skew-x-[-21.75deg] bg-white opacity-50 z-10 absolute [ w-full sm:w-fit h-sm sm:h-full ] left:none sm:left-[2.35rem] md:left-[3.7rem] lg:left-[5rem]"
      />

      <NuxtPicture
        v-if="heroImage(page)"
        :src="heroImage(page)"
        :alt="heroTitle(page)"
        sizes="200px md:300px lg:400px 2xl:800px"
        provider="cloudflare"
        fetch-priority="high"
        data-test-id="branchDetailsHeroImg"
        data-fs="branchDetailsHeroImg"
        :img-attrs="{ class: 'object-cover mx-auto w-full h-full' }"
      />
    </div>
  </Stack>
</template>

<script setup lang="ts">
import { BranchHeroProps, PageData } from "./branchDetailsTypes";
import { EXCLUDEDBRANCHES } from "~/helpers/excludedBranches";

const props = defineProps<BranchHeroProps>();

const { branch, page } = toRefs(props);

const { selectedBranch, selectBranch, isNewBranch } = useBranches();

const isHomeBranch = computed(() => branch.value?.[0]?.type === "home" || Number(branch?.value?.[0]?.id) === 80);

const shouldShowBranchSelect = computed(() => {
  return (
    branch.value?.[0]?.status === "open" &&
    branch.value?.[0].type === "build" &&
    Number(branch.value?.[0].id) !== EXCLUDEDBRANCHES.SCARBOROUGHCR
  );
});

const isUserSelectedBranch = computed(() => {
  return selectedBranch.value?.id === branch?.value?.[0]?.id;
});

const handleSelectBranch = () => {
  if (branch.value) {
    selectBranch(Number(branch.value[0]?.id));
  }
};

const content = (page: PageData) => {
  return page.getDocument()?.getData();
};

const heroTitle = (page: PageData) => {
  return content(page)?.title;
};

const stripHtmlTags = (html: string) => {
  return html.replace(/<\/?[^>]+(>|$)/g, "");
};

const heroDescription = (page: PageData) => {
  const rawDescription = content(page)?.content?.value;
  return rawDescription ? stripHtmlTags(rawDescription) : "";
};

const heroImage = (page: PageData) => {
  return page.getContent(content(page)?.headerImage?.$ref)?.original?.model?.links?.site?.href;
};
</script>
